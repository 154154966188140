<template>
  <div style="padding: 20px; font-size: 12px">
    <div v-html="agreementResult"></div>
  </div>
</template>

<script>
import { getAgreement } from "@network/api";

export default {
  name: "agreement",
  data() {
    return {
      agreementUrl: "",
      agreementResult: "",
    };
  },
  created() {
    // 注意url作为get请求的会直接作为网页内容返回的问题      先通过前端写死
    location.href.split('?')[0] ? this.agreementUrl = location.href.split('?')[0] : this.agreementUrl = location.href;
    location.href.split('?')[1] == 1 ? document.title = "完工证明指引" : document.title = '协议详情';
    // document.title = '协议详情';
    // this.agreementUrl = location.href;
    console.log(this.agreementUrl,'aaaaa',location.href.split('?'));
    this.agreementUrl =
      "enterpriseAgreement" + this.agreementUrl.split("agreement")[1];
    getAgreement(this.agreementUrl).then((res) => {
      if (res.code != "00000") {
        Dialog.alert({
          title: "错误",
          message: error,
        }).then(() => {});
        return;
      }
      this.agreementResult = res.data;
    });
  },
};
</script>

<style>
</style>
